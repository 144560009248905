import { css, Global, useTheme } from '@emotion/react';
import React, { PropsWithChildren, memo } from 'react';
import N11Meta from '~/features/navigation/components/N11Meta';
import N61Footer from '~/features/navigation/components/N61Footer';
import { StyledCheckoutWrapper, StyledMain } from '../../styled';
import { usePage } from '~/templates/pages';
import { CheckoutFooter, CheckoutHeader } from '~/features/checkout';
import NewSearchPage from '../../../NewSearchPage';
import { useMedia } from 'react-use';
import { queries } from '~/theme';
import N21MainMenuNew from '~/features/navigation/components/N21MainMenuNew';

const SolidMarbleGlobalStyles = () => {
    const { colors, fonts, focus, highlight } = useTheme();

    return (
        <Global
            styles={css`
                html {
                    background-color: ${colors.light};
                }

                body,
                input {
                    font-family: ${fonts.body};
                }

                *::selection {
                    background: ${highlight.background};
                    color: ${highlight.color};
                }

                *:focus-visible {
                    outline-offset: ${focus.outlineOffset};
                    outline-style: ${focus.outlineStyle};
                    outline-color: ${focus.outlineColor};
                    outline-width: ${focus.outlineWidth};
                }
            `}
        />
    );
};

const SolidMarbleLayout = memo(
    ({ children }: PropsWithChildren<Record<never, never>>): JSX.Element => {
        const { type } = usePage();
        const isSearchPage = type === ('m170searchPage' as unknown);
        const isCheckout = type === 'p100CheckoutPage';
        const isMobile = !useMedia(queries.md, false);
        const SearchComponentWrapper = isSearchPage && isMobile ? NewSearchPage : React.Fragment;

        if (isCheckout) {
            return (
                <>
                    <SolidMarbleGlobalStyles />
                    <StyledCheckoutWrapper>
                        <CheckoutHeader />
                        <StyledMain>{children}</StyledMain>
                        <CheckoutFooter />
                    </StyledCheckoutWrapper>
                </>
            );
        }

        return (
            <>
                <SolidMarbleGlobalStyles />
                <N11Meta />
                <N21MainMenuNew />
                <SearchComponentWrapper>
                    <StyledMain id="content">{children}</StyledMain>
                    <N61Footer />
                </SearchComponentWrapper>
            </>
        );
    }
);

export default SolidMarbleLayout;
